import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import { format } from 'date-fns';
import { FC } from 'react';

import {
  GET_MARKDOWN_DATA,
  GetMarkdownDataResponse,
} from './Markdown.gql.skip';
import {
  shouldDisplayPermanentMarkdown,
  shouldDisplayTemporaryMarkdown,
} from './utils';

import Logger from 'lib/utils/Logger';

import { OfferDetails } from 'types/generated/api';

import styles from './MarkdownText.module.scss';

export enum MarkdownDisplayMode {
  CTA = 'cart',
  DETAIL = 'pdp',
  SUMMARY = 'plp',
}

export type MarkdownTextProps = {
  markdownDisplayMode: MarkdownDisplayMode;
  offerDetails?: OfferDetails | null;
};

const TEMP_MARKDOWN_PREFIX = 'Limited Time Offer';
const DEFAULT_TIMEZONE = 'America/Los_Angeles';

const MarkdownText: FC<MarkdownTextProps> = ({
  markdownDisplayMode,
  offerDetails,
}: MarkdownTextProps) => {
  const { data, error, loading } = useQuery<GetMarkdownDataResponse>(
    GET_MARKDOWN_DATA
  );

  if (error) {
    Logger.error(
      'Something went wrong fetching markdown data from cache: ',
      error
    );
    return null;
  }

  const permanentMessageDuration =
    data?.getSiteFurnitureData?.markdown?.messageDuration;
  const permanentMessageText =
    data?.getSiteFurnitureData?.markdown?.messageText;

  if (loading) {
    return null;
  }

  const displayPermanentMarkdown = shouldDisplayPermanentMarkdown(
    offerDetails?.startDate,
    permanentMessageDuration,
    permanentMessageText,
    offerDetails?.offerType
  );
  const displayTemporaryMarkdown = shouldDisplayTemporaryMarkdown(
    offerDetails?.endDate,
    offerDetails?.offerType
  );

  if (!displayPermanentMarkdown && !displayTemporaryMarkdown) {
    return null;
  }

  let renderedText = permanentMessageText;
  if (displayTemporaryMarkdown && offerDetails?.endDate) {
    renderedText = TEMP_MARKDOWN_PREFIX;

    const pstDateString = new Date(offerDetails?.endDate).toLocaleDateString(
      'en-US',
      {
        timeZone: DEFAULT_TIMEZONE,
      }
    );
    const offerEndDate = format(new Date(pstDateString), 'MMMM do');

    if (markdownDisplayMode === MarkdownDisplayMode.DETAIL) {
      renderedText += ` ends ${offerEndDate}`;
    }
  }

  return (
    <div
      className={classnames(styles.root, styles[markdownDisplayMode])}
      data-test-id="MarkdownText-root"
    >
      {renderedText}
    </div>
  );
};

export default MarkdownText;
