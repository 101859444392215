import classnames from 'classnames';
import { FC, useCallback, useState } from 'react';

import Modal from 'components/Modals/Modal/Modal';

import Logger from 'lib/utils/Logger';

import styles from './PromoMessaging.module.scss';

type PromoMessagingProps = {
  code?: string;
  details?: string;
  discount?: number;
  message?: string;
  showModalAndButton?: boolean;
};

export const PROMO_DETAILS_MODAL_HEADER = 'Promo Code Details';

const PromoMessaging: FC<PromoMessagingProps> = ({
  code,
  details,
  discount,
  message,
  showModalAndButton,
}: PromoMessagingProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOnModalCloseClick = useCallback(() => setIsModalOpen(false), []);

  const handleBannerClick = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
    setIsModalOpen(true);
  }, []);

  if (!code || !discount) {
    return null;
  }

  const promoText = message ? message : `${discount}% OFF WITH CODE`;

  const {
    content: detailsContent,
    title: detailsTitle,
  } = splitHeaderFromDetailsHtml(decodeURIComponent(details || ''));

  try {
    return (
      <div className={classnames(message && styles.root)}>
        <span
          className={classnames(
            styles.header,
            !message && styles.abbreviatedText
          )}
        >
          {promoText}
        </span>
        {showModalAndButton && details && (
          <span
            className={styles.ctaLink}
            onClick={handleBannerClick}
            onKeyDown={handleBannerClick}
            role="presentation"
          >
            Details
          </span>
        )}
        {showModalAndButton && detailsContent && (
          <Modal
            header={renderModalHeader(detailsTitle)}
            isOpen={isModalOpen}
            onCloseClick={handleOnModalCloseClick}
          >
            <div
              className={styles.detailsText}
              dangerouslySetInnerHTML={{
                __html: detailsContent,
              }}
            />
          </Modal>
        )}
      </div>
    );
  } catch (error) {
    Logger.warn(
      `Unable to render PromoMessaging error for props: ${JSON.stringify({
        code,
        details,
        discount,
      })}`,
      error
    );
    return null;
  }
};

/**
 * Extracts Promo Code Details Title from the promoCode.details HTML content
 *
 * @param detailsHtml HTML block in the promo code details that contains H1 title
 */
export const splitHeaderFromDetailsHtml = (
  detailsHtml: string
): { content: string; title: string } => {
  try {
    const titleTag = detailsHtml.match(/(?:[<h1>])[\S\s]*(?:<\/h1>)/i)?.[0];
    const title =
      titleTag!.match(/(?![<h1>])[\S\s]*(?=<\/h1>)/i)?.[0] ||
      PROMO_DETAILS_MODAL_HEADER;
    const content = detailsHtml.replace(titleTag!, '');
    return { content, title };
  } catch (error) {
    // Error, but no big deal, means we don't have an H1 tag available, just return without crashing
    return { content: detailsHtml, title: PROMO_DETAILS_MODAL_HEADER };
  }
};

// Render HTML modal header for proper encoding and inner HTML rendering
const renderModalHeader = (content: string) => (
  <span
    dangerouslySetInnerHTML={{
      __html: content,
    }}
  />
);

export default PromoMessaging;
