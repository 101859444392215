import { gql } from '@apollo/client';

import { GetPromoCodeQuery } from 'types/generated/api';

export const GET_PROMO_CODE_QUERY = gql`
  query getPromoCode {
    promoCode {
      code
      details
      discounts {
        discount
        eligibility {
          type
          value
        }
      }
      endDate
      id
      minSubtotal
      startDate
    }
  }
`;

export type NonNullPromoCode = NonNullable<GetPromoCodeQuery>['promoCode'];
